.jodit-wysiwyg p,span,div {
    font-family: inherit !important;
}

.jodit .jodit-workplace .jodit-wysiwyg * {
    all: revert;
}

.jodit .jodit-workplace .jodit-wysiwyg h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.35;
    letter-spacing: 0.1px;
}

.jodit .jodit-workplace .jodit-wysiwyg h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 0.1px;
}

.jodit .jodit-workplace .jodit-wysiwyg h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 0.1px;
}

.jodit .jodit-workplace .jodit-wysiwyg h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.1px;
}

.jodit .jodit-workplace .jodit-wysiwyg h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.375;
    letter-spacing: 0.2px;
}

.jodit .jodit-workplace .jodit-wysiwyg p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.375;
    letter-spacing: 0.2px;
}

.jodit .jodit-workplace .jodit-wysiwyg blockquote {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.2px;
}

.jodit .jodit-workplace .jodit-wysiwyg tr td,
.jodit .jodit-workplace .jodit-wysiwyg tr th {
    border: 1px solid #ddd;
    vertical-align: middle;
    user-select: text;
    padding: 1px;
}

.jodit .jodit-workplace .jodit-wysiwyg table {
    border: none;
    border-collapse: collapse;
    empty-cells: show;
    table-layout: fixed;
    max-width: 100%;
}

.jodit .jodit-workplace .jodit-wysiwyg table tr {
    user-select: none;
}
